import { Sparkline } from "../Sparkline";
import type { Cell } from "./types";
import { default as Color } from "colorjs.io";
import * as colors from "../../../shared/colors";
import _ from "lodash";
import React from "react";
import clsx from "clsx";
import { Icons } from "../Icons";
import { ActionButtonsOverlay, type ActionButtonField } from "../ActionButtonsOverlay";

type CellColorInfo = {
    color: string,
    opacity: number,
    additionalClasses?: string[]
};

function getCellInfoForValue(cellColorValue: number | null | undefined): CellColorInfo {
    const normalizedCellColorValue = cellColorValue > 1 ?
        1 : cellColorValue < -1 ?
            -1 : cellColorValue ?? 0;

    const result = {
        color: null,
        opacity: Math.min(1, Math.max(0.3, Math.abs(normalizedCellColorValue))),
        additionalClasses: Math.abs(normalizedCellColorValue) > 0.95 ? ["highlight-cell"] : []
    };

    const colorRanges: [number, string][] = [
        [-1, colors.red_1],
        [-0.33, colors.orange_1],
        [0.33, colors.yellow_1],
        [1, colors.green_2]
    ];

    const lowerBound = _.orderBy(colorRanges, ["0"], ["desc"]).find(([value, _]) => value <= normalizedCellColorValue);
    const upperBound = colorRanges.find(([value, _]) => value >= normalizedCellColorValue);

    const baseColor = new Color(lowerBound[1]);
    const range = baseColor.range(upperBound[1], {
        space: "srgb-linear"
    });

    const rangeWidth = (upperBound[0] - lowerBound[0]) || 1;
    const normalizedValue = (normalizedCellColorValue - lowerBound[0]) / rangeWidth;

    const cellColor = range(normalizedValue);
    result.color = cellColor.display();

    return result;
}

type KpiMosaicCellProps = {
    gridArea: string,
    cellData: Cell,
    getCellText?: (cellData: Cell) => string,
    getCellSparkline?: (cellData: Cell) => { filled?: number[], line?: number[], dashed?: number[] },
    getCellColour?: (cellData: Cell) => number,
    getCellStyle?: (cellData: Cell) => React.CSSProperties,
    getMouseOverItems?: (cellData: Cell) => ActionButtonField[],
    getBusinessMapButtonVisibility?: (cellData: Cell) => boolean,
    getDashboardButtonVisibility?: (cellData: Cell) => boolean,
    onBusinessMapClick?: (cellData: Cell) => void,
    onDashboardClick?: (cellData: Cell) => void,
    mosaicContainerRef: React.MutableRefObject<HTMLDivElement>
};

export function KpiMosaicCell(props: KpiMosaicCellProps) {
    const { gridArea, cellData, getCellText, getCellSparkline, getCellColour, getCellStyle, getMouseOverItems, getBusinessMapButtonVisibility, getDashboardButtonVisibility, onBusinessMapClick, onDashboardClick, mosaicContainerRef } = props;

    const sparklineData = getCellSparkline?.(cellData);
    const { filled, line, dashed } = sparklineData ?? {};

    const cellText = getCellText?.(cellData);
    const cellStyle = getCellStyle?.(cellData) ?? {};

    const cellColorValue = getCellColour?.(cellData);
    const cellColorInfo = getCellInfoForValue(cellColorValue);

    const showBusinessMapButton = getBusinessMapButtonVisibility ? getBusinessMapButtonVisibility(cellData) : true;
    const showDashboardButton = getDashboardButtonVisibility ? getDashboardButtonVisibility(cellData) : true;

    const mouseOverItems = [
        showBusinessMapButton ? { data: cellData, icon: <Icons.Graph color="black" size="small" />, tooltip: "Navigate to Business Map", onClick: onBusinessMapClick, position: "top-left" } as ActionButtonField : null,
        showDashboardButton ? { data: cellData, icon: <Icons.TreeChart color="black" size="small" />, tooltip: "Navigate to Dashboard", onClick: onDashboardClick, position: "top-right" } as ActionButtonField : null,
        ...(getMouseOverItems?.(cellData) ?? [])
    ].filter(Boolean);

    return (
        <div key={gridArea} id={gridArea} className={clsx("cell", cellColorInfo.additionalClasses)} style={{ gridArea, "--cell-color": cellColorInfo.color, "--cell-opacity": cellColorInfo.opacity, ...cellStyle } as React.CSSProperties}>
            {
                sparklineData &&
                <Sparkline
                    key="sparkline"
                    sections={[
                        {
                            variant: "filled",
                            values: filled ?? [],
                            color: cellColorInfo.color
                        }, {
                            variant: "line",
                            values: line ?? [],
                            color: cellColorInfo.color
                        }, {
                            variant: "dashed",
                            values: dashed ?? [],
                            color: cellColorInfo.color
                        }
                    ].filter(s => s.values.length > 0) as any[]}
                />
            }
            <ActionButtonsOverlay className="cell-content" style={{ backgroundColor: !sparklineData && cellColorInfo.color }} items={mouseOverItems} containerRef={mosaicContainerRef}>
                <div className="cell-text">{cellText}</div>
            </ActionButtonsOverlay>
        </div>
    );
}