import "./DocumentationSample.css";
import { useCallback, useState } from "react";
import { Button, Card, CardContent, IconButton, Tooltip } from "../../../shared/components";
import { ContentRenderer } from "../ContentRenderer";
import { UnControlled as CodeMirror } from "react-codemirror2";
import type { Editor, EditorConfiguration } from "codemirror";
import "codemirror/mode/jsx/jsx";
import "codemirror/mode/css/css";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/xml-fold";
import "codemirror/addon/fold/comment-fold";
import "codemirror/addon/fold/markdown-fold";
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/scroll/simplescrollbars";
import "codemirror/addon/scroll/simplescrollbars.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import { CheckCircle, CopyAll, RestoreOutlined } from "@mui/icons-material";
import InlineContentErrorBoundary from "../../../shared/components/InlineContentErrorBoundary";

type DocumentationSampleProps = {
    input: string;
};

function trimExcessIndentation(input: string) {
    const leadingTabs = /^(\t*)/gm;
    const leadingSpaces = /^([ ]*)/gm;
    const blankLines = /^\s*\n/gm;
    const content = input.replaceAll(blankLines, "");

    const minLeadingTabs = content.matchAll(leadingTabs).map(m => m[1].length).reduce((a, b) => Math.min(a, b), Infinity);
    const minLeadingSpaces = content.matchAll(leadingSpaces).map(m => m[1].length).reduce((a, b) => Math.min(a, b), Infinity);

    if (minLeadingTabs > 0) {
        return input.replace(new RegExp(`^\\t{${minLeadingTabs}}`, "gm"), "").trim();
    }
    else if (minLeadingSpaces > 0) {
        return input.replace(new RegExp(`^[ ]{${minLeadingSpaces}}`, "gm"), "").trim();
    }

    return input.trim();
}

const editorOptions = {
    mode: "text/jsx",
    lineNumbers: true,
    indentUnit: 4,
    smartIndent: true,
    theme: "material",
    foldGutter: true,
    scrollbarStyle: "simple",
    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
} as EditorConfiguration;

const summaryOpeningTag = "\\n(?:\\s*)<!-- SUMMARY START -->(?:\\s*)\\n";
const summaryClosingTag = "\\n(?:\\s*)<!-- SUMMARY END -->(?:\\s*)\\n";
const summaryMatchRegex = new RegExp(`([\\S\\s]*)${summaryOpeningTag}([\\S\\s]*)${summaryClosingTag}([\\S\\s]*)`);

function DocumentationSample(props: DocumentationSampleProps) {
    const { input } = props;

    // Use Regex to extract the various parts of the source input...
    const summaryMatches = input.match(summaryMatchRegex);
    const containsSummary = !!summaryMatches;
    const prefix = summaryMatches?.[1] ?? "";
    const summary = summaryMatches?.[2] ?? input.trim();
    const suffix = summaryMatches?.[3] ?? "";

    // Reconstitute the original source code from the various parts (stripping out markers)...
    const fullSource = [prefix, summary, suffix].filter(s => s !== "").join("\n");

    const [editMode, setEditMode] = useState("summary");
    const [content, setContent] = useState(fullSource);
    const [editor, setEditor] = useState(null);
    const [copyIcon, setCopyIcon] = useState(<CopyAll />);

    const source = trimExcessIndentation(editMode === "summary" ? summary : fullSource);

    const switchEditMode = useCallback(() => {
        setEditMode(editMode === "summary" ? "full" : "summary");
    }, [editMode, editor]);

    const onSourceChange = useCallback((_editor, _data, newSource) => {
        setContent(
            editMode === "summary" ?
                [prefix, newSource, suffix].filter(s => s !== "").join("\n") :
                newSource
        );
    }, [setContent, editMode, prefix, suffix]);

    const copySource = useCallback(() => {
        navigator.clipboard.writeText(editor.doc.getValue());
        setCopyIcon(<CheckCircle color="success" />);
        setTimeout(() => {
            setCopyIcon(<CopyAll />);
        }, 1000);
    }, [editor]);

    const resetSource = useCallback(() => {
        editor.setValue(source);
    }, [editor, source]);

    const editorDidMount = useCallback((editor: Editor) => {
        setEditor(editor);
        setTimeout(() => editor.execCommand("goDocStart"), 50);
    }, [setEditor]);

    return (
        <Card variant="outlined" className="DocumentationSample">
            <CardContent className="content">
                <InlineContentErrorBoundary key={content}>
                    <ContentRenderer input={`jsx:${content}`} />
                </InlineContentErrorBoundary>
            </CardContent>
            <CardContent className="actions">
                <Tooltip title="Reset Code Changes" arrow>
                    <IconButton onClick={resetSource}><RestoreOutlined /></IconButton>
                </Tooltip>

                {
                    containsSummary && <Button variant="outlined" size="small" onClick={switchEditMode}>
                        {editMode === "summary" ? "Expand Code" : "Collapse Code"}
                    </Button>
                }

                <Tooltip title="Copy Source Code" arrow>
                    <IconButton onClick={copySource}>{copyIcon}</IconButton>
                </Tooltip>
            </CardContent>
            <CardContent className="editor">
                <CodeMirror
                    value={source}
                    options={editorOptions}
                    onChange={onSourceChange}
                    editorDidMount={editorDidMount}
                />
            </CardContent>
        </Card>
    );
}

export { DocumentationSample };