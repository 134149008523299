import "./Markdown.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia as style } from "react-syntax-highlighter/dist/esm/styles/prism";
import { DocumentedComponent } from "../../../shared/components/DocumentedComponent";
import _ from "lodash";
import { ContentRenderer } from "../ContentRenderer";
import { DocumentationSample } from "../DocumentationSample";
import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { TableOfContents } from "./TableOfContents";

const getElementId = (text: string) => text.toLowerCase().replace(/[^\w]+/g, '-');

const remarkPlugins = [remarkGfm];
const rehypePlugins = [rehypeRaw];

type MarkdownProps = {
    key?: string;
    source?: string;
    children?: any;
    disableAnchorGeneration?: boolean;
    renderTableOfContents?: boolean;
    [key: string]: any;
}

function Markdown(props: MarkdownProps) {
    const { key, source, children, disableAnchorGeneration, renderTableOfContents, ...rest } = props;

    // Workaround for the fact that JSX Parser wraps whitespace-enclosed elements in a fragment by default...
    const sanitizedChildren = _.isArray(children) ?
        children.find(c => _.isString(c)) :
        children?.type?.toString()?.indexOf("fragment") > -1 ? children?.children?.[0] :
            children;
    const content = source ?? sanitizedChildren;

    const contentRef = useRef<HTMLDivElement>(null);
    const contentElement = contentRef?.current;

    // Force re-render once-off to ensure that contentElement is available for the Table of Contents to attach itself to...
    const [isInitialized, setIsInitialized] = useState(false);
    useEffect(() => {
        setIsInitialized(true);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            const hash = window.location.hash.slice(1);
            if (hash) {
                const referencedElement = contentElement?.querySelector(`#${hash}`);
                if (referencedElement) {
                    referencedElement.scrollIntoView({ behavior: "smooth" });
                    return;
                }
            }

            contentElement?.scrollTo(0, 0);
        }, 50);
    }, [contentElement, content]);

    const components = useMemo(() => ({
        pre: ({children}) => {
            return children;
        },
        code: ({ node, className, children, ...props }) => {
            const match = /language-(\w+)/.exec(className || '');
            const language = match && match[1];

            if (language === "qerent") {
                return <ContentRenderer input={`jsx:${String(children)}`} />;
            }

            if (language === "documentation") {
                return <DocumentationSample input={String(children)} />;
            }

            return match ? (
                <SyntaxHighlighter
                    children={String(children).replace(/\n$/, '')}
                    style={style}
                    language={language}
                    PreTag="div"
                    {...props}
                />
            ) : (
                <code className={className} {...props}>
                    {children}
                </code>
            )
        },
        h1: ({ node, children, ...props }) => <h1 {...props} id={getElementId(String(children))}>{disableAnchorGeneration ? children : <a href={"#" + getElementId(String(children))}>{children}</a>}</h1>,
        h2: ({ node, children, ...props }) => <h2 {...props} id={getElementId(String(children))}>{disableAnchorGeneration ? children : <a href={"#" + getElementId(String(children))}>{children}</a>}</h2>,
        h3: ({ node, children, ...props }) => <h3 {...props} id={getElementId(String(children))}>{disableAnchorGeneration ? children : <a href={"#" + getElementId(String(children))}>{children}</a>}</h3>,
    }), [disableAnchorGeneration]);

    return (
        <div className={clsx("Markdown-container", renderTableOfContents && "with-table-of-contents")}>
            <div className="markdown" ref={contentRef} style={{ opacity: isInitialized ? 1 : 0 }}>
                <ReactMarkdown
                    key={key}
                    remarkPlugins={remarkPlugins}
                    rehypePlugins={rehypePlugins}
                    {...rest}
                    components={components}
                    children={content}
                />
            </div>
            {renderTableOfContents && isInitialized && contentElement && <TableOfContents content={content} contentElement={contentElement} />}
        </div>
    );
}

(Markdown as DocumentedComponent).metadata = {
    description: "The Markdown component renders the provided markdown as HTML.  See the [component's repository](https://github.com/rexxars/react-markdown) for the full documentation.",
    isSelfClosing: false,
    attributes: [
        { name: "disableAnchorGeneration", type: "boolean", description: "By default, the component will automatically generate URL fragment links for each heading.  This can be disabled by setting this prop to `true`." },
        { name: "renderTableOfContents", type: "boolean", description: "If `true`, the component will render a Table of Contents alongside the markdown content.  Optional - defaults to `false`." },
    ]
};

export default Markdown;