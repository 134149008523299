import * as _ from "lodash";
import moment from "moment";
import React from 'react';
import ReactDom from "react-dom/client";
import JsxParser from "@recursive-robot/react-jsx-parser";
import { connect } from "react-redux";
import Style from 'style-it';
import browserHistory from "../../shared/history";
import { getColumnDateTimeFormatter, getColumnNumberFormatter, formatDate, formatNumber, getNavigateToPageHandler } from "../../shared/utilities";
import { RootState } from "../../store";
import { showDetailedError, showError, showSuccess, showWarning, showInfo } from "../../store/notifications/actions";
import { applyParameterValueChanges, refreshDatasourceByName, updateParameterValue, goToID, goToXYZ, setDatasourceData, patchDatasourceData, setDefaultParameterValue, fetchDatasourceByName } from "../../store/storyline/actions";
import { Template } from "../../store/storyline/types";
import ScavengerFeedbackColumn from "../components/_bespoke/imperial/ScavengerFeedbackColumn";
import * as TableCellRenderers from "../components/Table/CellRenderers";
import "./Canvas.scss";
import { CanvasBindingsContext } from "../../shared/providers/CanvasBindingsProvider";
import { StaticPlotContext } from "../../shared/providers/StaticPlotProvider";
import { useSettings } from "../../shared/providers/SettingsProvider";
import * as FileSaver from "file-saver";
import clsx from "clsx";
import * as xlsx from "xlsx";
import * as Papa from "papaparse";
import { default as Color } from "colorjs.io";
import { logger } from "../../shared/services/loggingService";
import { NavFiltersParameterName } from "../components/NavFilters";
import { useAuth0 } from "../../auth/AuthContext";
import { ROLES } from "../../auth/types";
import { TooltipContainerContext } from "../../shared/providers/TooltipContainerProvider";
import { ErrorContainer } from "../../shared/pages/ErrorContainer";
import * as namedColors from "../../shared/colors";
import { useJsxParserComponents } from "../../shared/providers/JsxParserComponentsProvider";
import { CircularProgress } from "../../shared/components";


const blacklistedAttrs = [];
const blacklistedTags = [];
const renderError = ({ error }) => <ErrorContainer error={{type: "Generic", message: "An error occurred while parsing template.", details: error}} />;

// Backwards-compatibility shim for underscore-based array indexing of frame data...
function getUnderscoreBasedIndexingProxy(frameData: any) {
    return new Proxy(frameData, {
        get: function (target, field) {
            if (typeof field === "string") {
                // If exact path matches, return the value as-is...
                const value = target?.[field] ?? window?.[field];
                if (value !== undefined) {
                    return value;
                }
                // Else, try to parse an underscore-based index from the path...
                else if (field.indexOf("_") >= 0) {
                    const path = field.substr(0, field.lastIndexOf("_"));
                    const index = field.substr(field.lastIndexOf("_") + 1);

                    return target?.[path]?.[index];
                }
            }

            return target?.[field] ?? window?.[field];
        }
    });
}

interface Props {
    template: Template;
    frameData: any;
    parameterValues: Map<string, any>;
    datasourcesInFlight: Set<string>;
    canvasState: object;
    staticPlot: boolean;
    [key: string]: any;
}

function _Canvas(props: Props) {
    const { template, frameData, canvasState, navigateTo, updateParameterValueAction, setDefaultParameterValue, applyParameterValueChangesAction, parameterValues, datasourcesInFlight, staticPlot } = props;

    const components = useJsxParserComponents();
    const settings = useSettings();
    const { roles, userMetadata } = useAuth0();
    const isDeveloper = roles?.includes?.(ROLES.DEVELOPER);

    const containerRef = React.useRef<HTMLDivElement>(null);
    const [_isInitialized, setIsInitialized] = React.useState(false);

    React.useEffect(() => {
        setIsInitialized(true);
    }, []);

    const onError = React.useCallback((e) => {
        console.info("An error occurred during canvas rendering: ", e);
    }, []);

    // TODO: JSX parser now supports lambdas inside bindings, so these higher-order wrappers can be removed at some point...
    // JSX Parser doesn't support lambdas inside bindings, so we need a higher-order function to close over the slide id and expose a suitable parameterless function that can be bound to...
    const navigateToHandler = React.useCallback((slideId) => {
        return () => navigateTo(slideId);
    }, [navigateTo]);

    const updateParameterValue = React.useCallback(updateParameterValueAction, [updateParameterValueAction]);
    const applyParameterValueChanges = React.useCallback(applyParameterValueChangesAction, [applyParameterValueChangesAction]);

    const populatedParameterValues = Object.fromEntries(Array.from(parameterValues.entries()).filter(([_key, value]) => value !== null && value !== undefined));
    const navigateToPage = React.useCallback(getNavigateToPageHandler(populatedParameterValues, NavFiltersParameterName, browserHistory), [parameterValues, NavFiltersParameterName, populatedParameterValues, browserHistory]);

    const categorizedBindings = {
        canvasState,
        frameData,
        parameterValues: populatedParameterValues,
        functions: {
            navigateToHandler,
            applyParameterValueChanges,
            getColumnNumberFormatter,
            getColumnDateTimeFormatter,
            updateParameterValue,
            setDefaultParameterValue,
            clsx,
            formatNumber,
            formatDate,
            updateParameterValueAction: props.updateParameterValueAction,
            applyParameterValueChangesAction: props.applyParameterValueChanges,
            refreshDatasourceByName: props.refreshDatasourceByName,
            patchDatasourceData: props.patchDatasourceData,
            fetchDatasourceByName: props.fetchDatasourceByName,
            showError: props.showError,
            showSuccess: props.showSuccess,
            showDetailedError: props.showDetailedError,
            showWarning: props.showWarning,
            showInfo: props.showInfo,
            goToID: props.goToID,
            goToXYZ: props.goToXYZ,
            navigateToPage,
            trackEvent: logger.trackEvent.bind(logger),
            trackException: logger.trackException.bind(logger),
            trackTrace: logger.trackTrace.bind(logger),
            trackMetric: logger.trackMetric.bind(logger),
        },
        libraries: {
            moment,
            FileSaver,
            Object,
            xlsx,
            _,
            browserHistory,
            ReactDom,
            Papa,
            logger,
            Color,
        },
        metadata: {
            userMetadata,
            settings,
            datasourcesInFlight,
            namedColors,
        }
    };
    window["canvasBindings"] = categorizedBindings;

    const _bindings = {
        ...categorizedBindings.canvasState,
        ...props,
        ...categorizedBindings.frameData,
        ...categorizedBindings.parameterValues,
        ...categorizedBindings.functions,
        ...categorizedBindings.libraries,
        ...categorizedBindings.metadata,
        ScavengerFeedbackColumn,
        ...TableCellRenderers
    }
    _bindings.__proto__ = getUnderscoreBasedIndexingProxy(frameData);

    const defineFunction = React.useCallback((...args) => {
        try {
            return new Function(...args).bind(_bindings); // eslint-disable-line no-new-func
        }
        catch (e) {
            props.showDetailedError("defineFunction: Function body parsing failed.", e.toString());
        }
    }, [_bindings]);

    // eslint-disable-next-line no-eval
    const _eval = React.useCallback((expr) => eval(expr), []);

    if (!frameData) return <CircularProgress className="loading-spinner" />;

    const bindings = { ..._bindings, defineFunction, eval: _eval };
    bindings.__proto__ = getUnderscoreBasedIndexingProxy(frameData);

    const result = <div className="jsx-parser" ref={containerRef}>
        <StaticPlotContext.Provider value={staticPlot}>
            <TooltipContainerContext.Provider value={containerRef}>
                <CanvasBindingsContext.Provider value={bindings}>
                    <JsxParser
                        bindings={bindings}
                        components={components}
                        jsx={`<CanvasContentErrorBoundary>${template?.contents}</CanvasContentErrorBoundary>`}
                        showWarnings={false}
                        disableKeyGeneration={true}
                        blacklistedAttrs={blacklistedAttrs}
                        blacklistedTags={blacklistedTags}
                        renderError={renderError}
                        renderInWrapper={false}
                        key={template?.contents} // Force re-parse when template content is updated
                    />
                </CanvasBindingsContext.Provider>
            </TooltipContainerContext.Provider>
        </StaticPlotContext.Provider>
    </div>;

    return template?.customCss ?
        Style.it(template.customCss, result) :
        result;
}

const Canvas = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues,
        canvasState: state.storyline.canvasState,
        datasourcesInFlight: state.storyline.datasourcesInFlight
    }),
    {
        updateParameterValueAction: updateParameterValue,
        setDefaultParameterValue,
        applyParameterValueChangesAction: applyParameterValueChanges,
        refreshDatasourceByName,
        showError,
        showSuccess,
        showDetailedError,
        showWarning,
        showInfo,
        goToID,
        goToXYZ,
        setDatasourceData,
        patchDatasourceData,
        fetchDatasourceByName,
    })(_Canvas);

export default React.memo(Canvas);