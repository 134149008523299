import React from "react";
import { Auth0Context } from "../AuthContext";
import { logger } from "../../shared/services/loggingService";
import { AuthenticationResult, AuthError, BrowserCacheLocation, Configuration, EventType, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { LogLevel } from "@azure/msal-browser";

export type B2CAuthProviderProps = {
    children: any;
    tenantId: string;
    instance: string;
    clientId: string;
    domain: string;
    redirectUri: string;
    apiVersion: string;
    environment: string;
    onRedirectCallback: (state: object) => void;
}

export const B2CAuthProvider = (props: B2CAuthProviderProps) => {
    const { children, instance, clientId, domain, redirectUri, apiVersion, environment, onRedirectCallback } = props;

    const b2cPolicies = {
        names: {
            signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
            forgotPassword: 'BSC_1A_PASSWORDRESET',
            editProfile: 'B2C_1A_PROFILEEDIT',
        },
        authorities: {
            signUpSignIn: {
                authority: `${instance}${domain}/B2C_1A_SIGNUP_SIGNIN`,
            },
            forgotPassword: {
                authority: `${instance}${domain}/B2C_1A_PASSWORDRESET`,
            },
            editProfile: {
                authority: `${instance}${domain}/B2C_1A_PROFILEEDIT`,
            },
        },
        authorityDomain: instance.replace("https://", "").replace("/", ""),
    };

    const loginRequest: RedirectRequest = {
        scopes: ['openid', 'offline_access', clientId],
        extraQueryParameters: {
            "domain": window.location.hostname,
            "api-version": apiVersion,
            "env": environment
        },
        // Default redirect to current location - this will be overridden by private route redirects, if needed...
        state: JSON.stringify({ targetUrl: window.location.href })
    };

    const msalConfig: Configuration = {
        auth: {
            clientId: clientId, // This is the ONLY mandatory field that you need to supply.
            authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
            knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
            redirectUri: redirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
            postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
            // Explicitly provide the authority metadata to prevent an unnecessary request to the authority metadata endpoint on each page load.
            // This will only change if we make explicit changes to the B2C tenant's configuration, so it's safe to "cache" this value.
            authorityMetadata: `{
    "issuer": "https://qerent.b2clogin.com/bed0f7cf-d61b-4bd8-a794-22e2a77e83ca/v2.0/",
    "authorization_endpoint": "https://qerent.b2clogin.com/qerent.onmicrosoft.com/b2c_1a_signup_signin/oauth2/v2.0/authorize",
    "token_endpoint": "https://qerent.b2clogin.com/qerent.onmicrosoft.com/b2c_1a_signup_signin/oauth2/v2.0/token",
    "end_session_endpoint": "https://qerent.b2clogin.com/qerent.onmicrosoft.com/b2c_1a_signup_signin/oauth2/v2.0/logout",
    "jwks_uri": "https://qerent.b2clogin.com/qerent.onmicrosoft.com/b2c_1a_signup_signin/discovery/v2.0/keys",
    "response_modes_supported": [
        "query",
        "fragment",
        "form_post"
    ],
    "response_types_supported": [
        "code",
        "code id_token",
        "code token",
        "code id_token token",
        "id_token",
        "id_token token",
        "token",
        "token id_token"
    ],
    "scopes_supported": [
        "openid"
    ],
    "subject_types_supported": [
        "pairwise"
    ],
    "id_token_signing_alg_values_supported": [
        "RS256"
    ],
    "token_endpoint_auth_methods_supported": [
        "client_secret_post",
        "client_secret_basic"
    ],
    "claims_supported": [
        "name",
        "given_name",
        "family_name",
        "email",
        "sub",
        "tid",
        "user_id",
        "domain",
        "tenant_name",
        "roles",
        "allowed_tags",
        "denied_tags",
        "user_metadata",
        "iss",
        "iat",
        "exp",
        "aud",
        "acr",
        "nonce",
        "auth_time"
    ]
}`,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            temporaryCacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                },
            },
        },
    };

    const [client] = React.useState(new PublicClientApplication(msalConfig));
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        client.handleRedirectPromise()
            .then(response => {
                setLoading(false);
            })
            .catch((err: AuthError) => {
                setLoading(false);
                const sanitizedErrorMessage = err.errorMessage?.split?.("\n")?.[0]?.replace?.("AADB2C: ", "");
                setError(sanitizedErrorMessage);
                onRedirectCallback && onRedirectCallback({ targetUrl: `/login-callback?original-path=${window.location.href}` });
            });
    }, [client]);

    React.useEffect(() => {
        const callbackId = client.addEventCallback((event) => {
            if (
              (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                (event.payload as AuthenticationResult).account
            ) {
                const authResult = event.payload as AuthenticationResult;
                const account = authResult.account;
                client.setActiveAccount(account);
                logger.trackEvent({ name: "Login" }, account);
            }

            // Check for forgot password error
            // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
            if (event.error && (event.error as AuthError)?.errorMessage?.includes?.('AADB2C90118')) {
                const resetPasswordRequest = {
                    ...loginRequest,
                    authority: b2cPolicies.authorities.forgotPassword.authority
                };
                client.loginRedirect(resetPasswordRequest);
            }
        });

        return () => {
            if (callbackId) {
                client.removeEventCallback(callbackId);
            }
        };
    }, [client]);

    const activeUser = client.getActiveAccount();
    if (activeUser != null) {
        logger.setAuthenticatedUserContext(activeUser.idTokenClaims["email"] as string);
    }

    const roles = activeUser?.idTokenClaims["roles"] as string[] ?? [];
    const allowedTags = activeUser?.idTokenClaims["allowed_tags"] as string[] ?? [];
    const deniedTags = activeUser?.idTokenClaims["denied_tags"] as string[] ?? [];
    const effectiveTags = allowedTags.filter(t => !deniedTags.includes(t));

    return (
        <Auth0Context.Provider
            value={{
                onRedirectCallback,
                isAuthenticated: activeUser !== null,
                user: { name: activeUser?.name },
                roles: roles,
                allowedTags: allowedTags,
                deniedTags: deniedTags,
                effectiveTags: effectiveTags,
                userMetadata: Object.fromEntries(
                    [
                        // Non-standard claim names, as returned originally by the "getUserMetadata" function call...
                        ["EmailAddress", activeUser?.idTokenClaims["email"] ?? ""],
                        ["UserId", activeUser?.idTokenClaims["user_id"] ?? ""],
                        // Standardized claims...
                        ["domain", activeUser?.idTokenClaims["domain"] ?? ""],
                        ["tenant_name", activeUser?.idTokenClaims["tenant_name"] ?? ""],
                        ["roles", activeUser?.idTokenClaims["roles"] ?? []],
                        ["user_id", activeUser?.idTokenClaims["user_id"] ?? ""],
                        ["name", activeUser?.idTokenClaims["name"] ?? ""],
                        ["email", activeUser?.idTokenClaims["email"] ?? ""],
                        // User metadata...
                        ...((activeUser?.idTokenClaims["user_metadata"] ?? []) as string[]).map(s => s.split("::")),
                    ]
                ),
                loading: client === null,
                error: error,
                loginWithPopup: (...a) => client.loginPopup(...a),
                handleRedirectPromise: (...p) => client.handleRedirectPromise(...p),
                getIdTokenClaims: (p) => client.getActiveAccount()?.idTokenClaims,
                loginWithRedirect: (p) => client.loginRedirect({...loginRequest, ...p }),
                getTokenSilently: async () => {
                    try {
                        const token = await client.acquireTokenSilent({ ...loginRequest });
                        if (!token.accessToken) {
                            return client.loginRedirect({ ...loginRequest });
                        }
                        return token.accessToken;
                    }
                    catch (err) {
                        return client.loginRedirect({ ...loginRequest });
                    }
                },
                getTokenWithPopup: (req) => client.acquireTokenPopup(req),
                logout: (...p) => client.logoutRedirect(...p)
            }}
        >
            {!loading && children}
        </Auth0Context.Provider>
    );
};