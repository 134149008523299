import _ from "lodash";
import addRetryLogicToFetch from "fetch-retry";
import { attachToEnvironment as attachFetchInterceptorToEnvironment } from "./services/fetchInterceptService";
import { default as Color } from "colorjs.io";

// Make lodash available globally...
window._ = _;

// Backwards-compatibility - Make colorjs.io available globally (this library was originally added before it was available as a module, so it was globally available)...
window["Color"] = Color;

/// Use ISO 8601 format for all serialized dates...
Date.prototype.toString = Date.prototype.toISOString; // eslint-disable-line no-extend-native


/// Monkey-patch fetch() to retry failed requests and allow request interception...
const { fetch: originalFetch } = window;
const fetchWithRetry = addRetryLogicToFetch(originalFetch);
const defaultRetryOptions = {
    retries: 3,
    // Exponential backoff...
    retryDelay: function (attempt, _error, _response) {
        return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
    },
    retryOn: [
        408, // Request Timeout
        429, // Too Many Requests
        500, // Internal Server Error
        502, // Bad Gateway
        503, // Service Unavailable
        504, // Gateway Timeout
    ]
};
window.fetch = (url, options) => fetchWithRetry(url, { ...defaultRetryOptions, ...options }); // Use default retry options, with any overrides from the caller...
const fetchIntercept = attachFetchInterceptorToEnvironment(window);
export { fetchIntercept };

/// By default, strip all React/DOM elements, functions and symbols when serializing JSON data.
/// Some third-party libraries (react-grid) are using raw JSON.stringify calls for change tracking, so any JSX in table cells will cause a circular reference error.
/// This default implementation of the replacer function prevents circular reference errors from occuring and improves serialization performance in some cases.
const originalStringify = JSON.stringify;
JSON.stringify = (value: any, replacer?: (number | string)[] | ((this: any, key: string, value: any) => any), space?: string | number) => {
    if (!!replacer) {
        return originalStringify(value, replacer as any, space);
    }

    function transientEntryReplacer(_key, value) {
        if (_.isFunction(value) || _.isElement(value) || _.isSymbol(value) || _.has(value, "$$typeof")) {
            return undefined;
        }

        return value;
    };

    return originalStringify(value, transientEntryReplacer, space);
};
